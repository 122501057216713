#homepage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #F8F6F5;
}


.hero {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  min-height: 100vw;
  background-position: 75% 50%;
  background-size: cover;

  &, & * {
    color: white;
  }

  @media only screen and (min-width: 530px) {
    min-height: 400px;
    padding: $padding;
  }

  @media only screen and (min-width: 1300px) {
    padding: $padding*2;
  }
}

.hero__logo {
  position: absolute;
  width: 180px;
  bottom: $padding;
  right: $padding;

  @media only screen and (min-width: 1300px) {
    right: $padding*2;
    bottom: $padding*2;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.hero__title {
  position: absolute;
  top: 58vw;
  left: calc(10% + #{$padding}/2);

  &, & * {
    font-family: 'Almarena';
  }

  @media only screen and (min-width: 530px) {
    left: calc(50px + #{$padding});
    top: 307px;
  }
  @media only screen and (min-width: 900px) {
    left: $padding;
    top: initial;
    bottom: $padding;

    h2 {
      font-size: #{$h1-font-size}rem;
    }
  }
  @media only screen and (min-width: 1300px) {
    left: $padding*2;
    bottom: $padding*2;
  }

  &-br {
    @media only screen and (min-width: 900px) {
      display: none;
    }
  }
}

.hero__brand {
  position: absolute;
  top: $padding;
  left: $padding;
  max-width: 500px;
  width: calc(100% - #{$padding}*2);

  @media only screen and (min-width: 1300px) {
    left: $padding*2;
    bottom: $padding*2;
  }

  img {
    width: 100%;
    height: auto;
    margin-left: -2%;
  }
}

.nav-menu {
  flex-shrink: 1;
  z-index: 2;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__current-language {
    text-transform: uppercase;
  }
}

.nav-menu__opt {
  position: relative;
  @extend .h4--heading;

  & + & {
    padding-top: 0;
  }

  &-text {
    text-decoration: none;
    color: inherit;
    line-height: inherit;
    width: 100%;
    padding: $padding*1.2 $padding;
    display: block;
    line-height: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      display: block;
      text-decoration: none;
    }
  }

  &-pin {
    display: inline-block;

    .fa-thumbtack {
      height: 1.5em;
      width: 1.5em;
      padding-left: 0.5em;
      margin: 0;
      display: block;
      padding-top: 0.2em;
      opacity: 0.3;

      .pinned & {
        opacity: 1;
      }
    }
  }
}


.feed-slideshow {
  max-height: 50vh;
  display: block;

  [data-glide-slideshow] {
    background: transparent;
    width: 100%;
    height: auto;
    margin: 0;
  }

  .glide__slide {
    padding: $padding*2;
    max-height: 100%;
  }
}



.strip-color {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: $padding/2;
  background-color: $color-magenta;
}
