$base-font-size: 17;
$h1-font-size: math.div(36, $base-font-size);
$h2-font-size: math.div(28, $base-font-size);
$h4-font-size: math.div(18, $base-font-size);

$color-white: #d9d9d9;
$color-black: #1d1d1b;


$color-magenta: #ab3388;
$color-magenta-light: #c948a3;
$color-orange: #ff9328;
$color-yellow: #f7b731;
$color-dark-green: #046c34;
$color-yellow: #f7b731;
$color-red: #fc5c65;
$color-light-blue: #4fc6f9;
$color-light-green: #9abd4f;

$color-blue: #4b7bec;
$color-blue-variation: #6e95ef;
$color-blue-variation--light: #8baaf2;
$color-green: #2bcbba;
$color-green-light: #46d8c8;

$color-yellow-light: #f7b731cf;

$padding: 15px;
