html {
  height: 100%;
}

body {
  height: calc(100% - (#{$padding}*1.2*2 + #{$h4-font-size}em));
  opacity: 0;

  &.body-ready {
    opacity: 1;
  }
}

.text-underline,
a {
  color: inherit;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.15em;

  &.link--not-underlined {
    text-decoration: none;
  }
}

.bg-color--orange {
  background-color: $color-orange;
}
.bg-color--itinerary,
.bg-color--itinerary-info,
.bg-color--magenta {
  background-color: $color-magenta;

  .nav-menu__opt {
    background-color: $color-magenta;
  }

  .ma-accordion__content .nav-menu__opt {
    background-color: $color-magenta-light;
  }
}

.bg-color--itinerary-light,
.bg-color--itinerary,
.bg-color--itinerary-info,
.bg-color--magenta {
  color: white;

  .nav-menu__opt-text {
    color: white;
  }

  .heading__back-home svg {
    line {
      stroke: white;
    }
    polygon {
      fill: white;
    }
  }
}

.bg-color--itinerary-light,
.bg-color--magenta-light {
  background-color: $color-magenta-light;
}
.bg-color--red {
  background-color: $color-red;
}
.bg-color--light-blue {
  background-color: $color-light-blue;
}
.bg-color--light-green {
  background-color: $color-light-green;
}
.bg-color--dark-green {
  background-color: $color-dark-green;
}
.bg-color--green {
  background-color: $color-green;
}
.bg-color--green-light {
  background-color: $color-green-light;
}
.bg-color--black {
  background-color: black;

  .nav-menu__opt-text {
    color: white;
  }
}
.bg-color--white {
  background-color: #f7f7f7;

  .nav-menu__opt {
    background-color: #f7f7f7;
  }

  .ma-accordion__content .nav-menu__opt {
    background-color: white;
  }
}

.bg-color--about,
.bg-color--yellow {
  background-color: $color-yellow;
}
.bg-color--about-light,
.bg-color--yellow--light {
  background-color: $color-yellow-light;
}
.bg-color--blue {
  background-color: $color-blue;
}
.bg-color--blue-variation {
  background-color: $color-blue-variation;
}
.bg-color--blue-variation-light {
  background-color: $color-blue-variation--light;
}
