@use "sass:math";
@use "sass:string";


// 1. ABSTRACTS
@import 'abstracts/mixins';
@import 'abstracts/fonts';
@import 'abstracts/variables';

// 2. VENDORS
@import 'vendors';

// 3. VENDORS EXTENSIONS
@import 'vendor-extensions/glide';
@import 'vendor-extensions/font-awesome';
@import 'vendor-extensions/pswp';

// 4. BASE
@import 'base/base';
@import 'base/typography';

// 5. LAYOUT
@import 'layout/general';
@import 'layout/heading';

// 6. COMPONENTS
@import 'components/button';
@import 'components/accordion';
@import 'components/icons';
@import 'components/slideshow';
@import 'components/loading-icon';
@import 'components/point-info';

// 7. PAGES
@import 'pages/index';
@import 'pages/itinerary';
@import 'pages/map';
