*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

:where(body) {
  margin: 0;
}

:where(h1) {
  margin: .67em 0;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

:where(hr) {
  color: inherit;
  height: 0;
}

:where(nav) :where(ol, ul) {
  padding: 0;
  list-style-type: none;
}

:where(nav li):before {
  content: "​";
  float: left;
}

:where(pre) {
  font-family: monospace;
  font-size: 1em;
  overflow: auto;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

:where(iframe) {
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

:where(table) {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

:where(button, input, select) {
  margin: 0;
}

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

:where(progress) {
  vertical-align: baseline;
}

:where(textarea) {
  resize: vertical;
  margin: 0;
}

:where([type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(dialog) {
  color: #000;
  background-color: #fff;
  border: solid;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(details > summary:first-of-type) {
  display: list-item;
}

:where([aria-busy="true" i]) {
  cursor: progress;
}

:where([aria-controls]) {
  cursor: pointer;
}

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@font-face {
  font-family: Almarena;
  src: url("AlmarenaNeue-DisplayLight.f357fbfe.woff") format("woff"), url("AlmarenaNeue-DisplayLight.a51c1d14.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Almarena;
  src: url("AlmarenaNeue-DisplayRegular.fd2aa8d8.woff") format("woff"), url("AlmarenaNeue-DisplayRegular.862ddb96.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Almarena;
  src: url("AlmarenaNeue-DisplayMedium.0599ad62.woff") format("woff"), url("AlmarenaNeue-DisplayMedium.2c7370d3.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

.glide__track, .glide__slides, .glide__slide {
  width: 100%;
  height: 100%;
}

.glide__slide {
  padding-top: 0;
  transform: translate3d(0, 0, 0);
}

[data-glide-captions] > * {
  display: none;
}

[data-glide-captions] > .active {
  display: block;
}

.glide__arrow {
  box-shadow: none;
  text-shadow: none;
  color: #d9d9d9;
  border: 0;
  border-radius: 0;
  height: 1em;
  margin: 0;
  padding: 0;
  font-size: 1.7em;
  font-weight: normal;
  left: 15px;
}

.glide__arrow svg {
  vertical-align: initial;
  height: 100%;
}

.glide__arrow svg polygon {
  fill: #1d1d1b;
  opacity: .8;
}

.glide__arrow--next {
  left: initial;
  right: 15px;
}

i.fas, i.fab {
  margin-right: .3em;
  font-size: .9em;
}

.pswp__button--arrow--left:before {
  width: .7em;
  left: 10px;
  background-color: #0000 !important;
  background-image: url("arrow-slide-outline--white.e9c4deb0.svg") !important;
  background-position: center !important;
  background-size: contain !important;
}

.pswp__button--arrow--right:before {
  width: .7em;
  right: 10px;
  background-color: #0000 !important;
  background-image: url("arrow-slide-outline--white--right.f5f3b769.svg") !important;
  background-position: center !important;
  background-size: contain !important;
}

.pswp__counter {
  color: #fff;
  font-weight: bold;
}

.pswp--visible .pswp__bg {
  opacity: .5 !important;
}

html {
  height: 100%;
}

body {
  opacity: 0;
  height: calc(100% + -36px - 1.05882em);
}

body.body-ready {
  opacity: 1;
}

.text-underline, a {
  color: inherit;
  text-underline-offset: .15em;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.text-underline.link--not-underlined, a.link--not-underlined {
  text-decoration: none;
}

.bg-color--orange {
  background-color: #ff9328;
}

.bg-color--itinerary, .bg-color--itinerary-info, .bg-color--magenta, .bg-color--itinerary .nav-menu__opt, .bg-color--itinerary-info .nav-menu__opt, .bg-color--magenta .nav-menu__opt {
  background-color: #ab3388;
}

.bg-color--itinerary .ma-accordion__content .nav-menu__opt, .bg-color--itinerary-info .ma-accordion__content .nav-menu__opt, .bg-color--magenta .ma-accordion__content .nav-menu__opt {
  background-color: #c948a3;
}

.bg-color--itinerary-light, .bg-color--itinerary, .bg-color--itinerary-info, .bg-color--magenta, .bg-color--itinerary-light .nav-menu__opt-text, .bg-color--itinerary .nav-menu__opt-text, .bg-color--itinerary-info .nav-menu__opt-text, .bg-color--magenta .nav-menu__opt-text {
  color: #fff;
}

.bg-color--itinerary-light .heading__back-home svg line, .bg-color--itinerary .heading__back-home svg line, .bg-color--itinerary-info .heading__back-home svg line, .bg-color--magenta .heading__back-home svg line {
  stroke: #fff;
}

.bg-color--itinerary-light .heading__back-home svg polygon, .bg-color--itinerary .heading__back-home svg polygon, .bg-color--itinerary-info .heading__back-home svg polygon, .bg-color--magenta .heading__back-home svg polygon {
  fill: #fff;
}

.bg-color--itinerary-light, .bg-color--magenta-light {
  background-color: #c948a3;
}

.bg-color--red {
  background-color: #fc5c65;
}

.bg-color--light-blue {
  background-color: #4fc6f9;
}

.bg-color--light-green {
  background-color: #9abd4f;
}

.bg-color--dark-green {
  background-color: #046c34;
}

.bg-color--green {
  background-color: #2bcbba;
}

.bg-color--green-light {
  background-color: #46d8c8;
}

.bg-color--black {
  background-color: #000;
}

.bg-color--black .nav-menu__opt-text {
  color: #fff;
}

.bg-color--white, .bg-color--white .nav-menu__opt {
  background-color: #f7f7f7;
}

.bg-color--white .ma-accordion__content .nav-menu__opt {
  background-color: #fff;
}

.bg-color--about, .bg-color--yellow {
  background-color: #f7b731;
}

.bg-color--about-light, .bg-color--yellow--light {
  background-color: #f7b731cf;
}

.bg-color--blue {
  background-color: #4b7bec;
}

.bg-color--blue-variation {
  background-color: #6e95ef;
}

.bg-color--blue-variation-light {
  background-color: #8baaf2;
}

html {
  color: #1d1d1b;
  font-size: 17px;
}

* {
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  line-height: 1.36;
}

p + p {
  padding-top: 1em;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.h1--heading, .h1--heading *, h1, h1 * {
  font-size: 2.11765rem;
  font-weight: 700;
  line-height: 1.2;
}

.h2--heading, .h2--heading *, h2, h2 * {
  font-size: 1.64706rem;
  font-weight: 600;
  line-height: 1.2;
}

.h4--heading, .nav-menu__opt, .heading__txt, .h4--heading *, .nav-menu__opt *, .heading__txt *, h4, h4 * {
  font-size: 1.05882rem;
  font-weight: 600;
  line-height: 1.2;
}

h5, h5 * {
  font-size: 1.05882rem;
  line-height: 1.2;
}

li + li {
  padding-top: .5em;
}

.page-content {
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
}

.block-text-container {
  max-width: 992px;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 15px 0;
}

@media only screen and (min-width: 900px) {
  .block-text-container {
    padding-bottom: 15px;
  }
}

.block-text-container h1:not(:first-child), .block-text-container h2:not(:first-child), .block-text-container h3:not(:first-child), .block-text-container h4:not(:first-child), .block-text-container h5:not(:first-child), .block-text-container h6:not(:first-child) {
  margin-top: 1.5em;
}

.block-text-container h1:not(:last-child), .block-text-container h2:not(:last-child), .block-text-container h3:not(:last-child), .block-text-container h4:not(:last-child), .block-text-container h5:not(:last-child), .block-text-container h6:not(:last-child) {
  margin-bottom: 1em;
}

.heading {
  z-index: 2;
  align-items: center;
  width: 100%;
  padding: 18px 15px;
  display: flex;
  position: fixed;
  top: 0;
}

.heading + .page-content {
  margin-top: calc(36px + 1.05882em);
}

.heading__txt {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 30px);
  padding-left: .423529em;
  padding-right: .423529em;
  line-height: 1;
  overflow: hidden;
}

.heading__back-home {
  cursor: pointer;
  width: 1.27059em;
  height: 1.27059em;
}

.heading__back-home svg {
  object-fit: contain;
  width: auto;
  height: 100%;
  display: block;
}

.heading__back-home svg line {
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 3.7px;
}

.heading__back-home svg polygon {
  fill: #000;
}

.heading__back-home.heading__back-home--light svg line {
  stroke: #fff;
}

.heading__back-home.heading__back-home--light svg polygon {
  fill: #fff;
}

.heading__back-home.heading__back-home--dark svg line {
  stroke: #000;
}

.heading__back-home.heading__back-home--dark svg polygon {
  fill: #000;
}

.heading__info i {
  margin: 0;
  font-size: 1rem;
}

.nei-button {
  background-color: #fff;
  border: 0;
  border-radius: 1em;
  outline: 0;
  margin: 2em auto calc(2em - 15px);
  padding-left: .5em;
  padding-right: .5em;
  font-size: 1.5em;
  display: block;
}

.nei-button:before {
  content: "";
  background-image: url("arrow-btn.fed74102.svg");
  width: .7em;
  height: .7em;
  margin-top: .2em;
  margin-right: .3em;
  transition: all .2s;
  display: inline-block;
}

.nei-button:hover:before {
  margin-left: .15em;
  margin-right: .15em;
}

.ma-accordion {
  height: auto;
  overflow: hidden;
}

[data-toggle-accordion]:hover {
  cursor: pointer;
}

[data-toggle-accordion] > span:after {
  content: "";
  background-image: url("arrow-slide--white.84cf3b45.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: .8em;
  height: .8em;
  margin-top: .2em;
  margin-left: .5em;
  position: absolute;
  right: 15px;
  transform: rotate(-90deg);
}

.bg-color--white [data-toggle-accordion] > span:after {
  background-image: url("arrow-slide.14a3a58a.svg");
}

.tab--active [data-toggle-accordion] > span:after {
  margin-top: .2em;
  transform: rotate(90deg);
}

.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: .95em;
  height: .95em;
  display: inline-block;
}

.icon--close {
  background-image: url("icon-close.39110e61.svg");
}

.ma-slideshow {
  margin-top: 30px;
}

[data-glide-slideshow] {
  width: calc(100% + 30px);
  height: 66.6667vw;
  margin-left: -15px;
  margin-right: -15px;
}

@media only screen and (min-width: 992px) {
  [data-glide-slideshow] {
    width: 100%;
    height: 661.333px;
    margin-left: 0;
    margin-right: 0;
  }
}

[data-glide-slideshow] img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.glide__slides {
  margin-top: 0;
}

[data-glide-captions] {
  margin-top: 7.5px;
  font-size: .8em;
}

.fullwidth-image {
  width: calc(100% + 30px);
  height: auto;
  margin-top: 30px;
  margin-left: -15px;
  margin-right: -15px;
}

@media only screen and (min-width: 992px) {
  .fullwidth-image {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.fullwidth-image img {
  width: 100%;
  height: auto;
}

.loading-icon {
  width: 80px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.loading-icon svg {
  transform-origin: center;
  width: 100%;
  height: auto;
  animation: 4s linear infinite rotating;
}

@keyframes rotating {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.point-info {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  overflow-y: hidden;
}

.point-info--open {
  pointer-events: all;
  overflow-y: auto;
}

.point-info__num {
  height: 1.3em;
  margin-left: -.2em;
  display: inline-block;
  position: relative;
  top: -.1em;
}

.point-info__num svg {
  width: auto;
  height: 100%;
}

.point-info__container {
  background-color: #0000;
  padding-top: 7em;
  transition: background-color .3s ease-in-out;
}

.point-info--open .point-info__container {
  background-color: #0000001a;
}

.point-info__close-icon {
  opacity: 0;
  width: 2em;
  height: 2em;
  transition: opacity .3s ease-in-out;
  position: fixed;
  top: calc(51px + 1.05882em);
  right: 15px;
}

.point-info--open .point-info__close-icon {
  opacity: 1;
}

.point-info__close-icon svg {
  width: 100%;
  height: auto;
}

.point-info__close-icon svg path {
  filter: drop-shadow(1px 1px 1px #0003);
}

.point-info__content {
  background-color: #fff;
  height: 100%;
  margin-top: calc(100vh - 7em);
  transition: margin-top .3s ease-in-out;
}

.point-info--open .point-info__content {
  margin-top: 0;
}

.point-info__content .block-text-container {
  min-height: calc(100vh - 8.3em - 30px);
}

.point-info__paths {
  padding-top: 2em;
  padding-bottom: 3em;
}

.point-info__path {
  border-radius: .3em;
  margin-bottom: .5em;
  margin-right: .5em;
  padding: .3em .6em;
  font-size: .8em;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

.point-info__details {
  flex-direction: column;
  padding: 0;
  display: flex;
}

.point-info__detail-item {
  margin-bottom: .5em;
}

#homepage {
  background-color: #f8f6f5;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden auto;
}

.hero {
  background-position: 75%;
  background-size: cover;
  flex-grow: 1;
  height: 100%;
  min-height: 100vw;
  position: relative;
  overflow: hidden auto;
}

.hero, .hero * {
  color: #fff;
}

@media only screen and (min-width: 530px) {
  .hero {
    min-height: 400px;
    padding: 15px;
  }
}

@media only screen and (min-width: 1300px) {
  .hero {
    padding: 30px;
  }
}

.hero__logo {
  width: 180px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

@media only screen and (min-width: 1300px) {
  .hero__logo {
    bottom: 30px;
    right: 30px;
  }
}

.hero__logo img {
  width: 100%;
  height: auto;
}

.hero__title {
  position: absolute;
  top: 58vw;
  left: calc(10% + 7.5px);
}

.hero__title, .hero__title * {
  font-family: Almarena;
}

@media only screen and (min-width: 530px) {
  .hero__title {
    top: 307px;
    left: 65px;
  }
}

@media only screen and (min-width: 900px) {
  .hero__title {
    left: 15px;
    top: initial;
    bottom: 15px;
  }

  .hero__title h2 {
    font-size: 2.11765rem;
  }
}

@media only screen and (min-width: 1300px) {
  .hero__title {
    bottom: 30px;
    left: 30px;
  }
}

@media only screen and (min-width: 900px) {
  .hero__title-br {
    display: none;
  }
}

.hero__brand {
  width: calc(100% - 30px);
  max-width: 500px;
  position: absolute;
  top: 15px;
  left: 15px;
}

@media only screen and (min-width: 1300px) {
  .hero__brand {
    bottom: 30px;
    left: 30px;
  }
}

.hero__brand img {
  width: 100%;
  height: auto;
  margin-left: -2%;
}

.nav-menu {
  z-index: 2;
  flex-shrink: 1;
}

.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu__current-language {
  text-transform: uppercase;
}

.nav-menu__opt {
  position: relative;
}

.nav-menu__opt + .nav-menu__opt {
  padding-top: 0;
}

.nav-menu__opt-text {
  color: inherit;
  line-height: inherit;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 18px 15px;
  line-height: 1;
  text-decoration: none;
  display: flex;
}

.nav-menu__opt-text a {
  text-decoration: none;
  display: block;
}

.nav-menu__opt-pin {
  display: inline-block;
}

.nav-menu__opt-pin .fa-thumbtack {
  opacity: .3;
  width: 1.5em;
  height: 1.5em;
  margin: 0;
  padding-top: .2em;
  padding-left: .5em;
  display: block;
}

.pinned .nav-menu__opt-pin .fa-thumbtack {
  opacity: 1;
}

.feed-slideshow {
  max-height: 50vh;
  display: block;
}

.feed-slideshow [data-glide-slideshow] {
  background: none;
  width: 100%;
  height: auto;
  margin: 0;
}

.feed-slideshow .glide__slide {
  max-height: 100%;
  padding: 30px;
}

.strip-color {
  background-color: #ab3388;
  width: 7.5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.roteiros-coimbra-map {
  position: absolute;
  inset: 0;
}

/*# sourceMappingURL=index.ec1b48ee.css.map */
