.page-content {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
}

.block-text-container {
  padding: $padding * 2 $padding;
  padding-bottom: 0;
  min-height: 100%;
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 900px) {
    padding-bottom: $padding;
  }

  h1:not(:first-child),
  h2:not(:first-child),
  h3:not(:first-child),
  h4:not(:first-child),
  h5:not(:first-child),
  h6:not(:first-child) {
    margin-top: 1.5em;
  }

  h1:not(:last-child),
  h2:not(:last-child),
  h3:not(:last-child),
  h4:not(:last-child),
  h5:not(:last-child),
  h6:not(:last-child) {
    margin-bottom: 1em;
  }
}
